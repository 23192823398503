<script setup>
import { useTranslationStore } from '@/stores/translation';
import FooterComponent from '@/components/FooterComponent.vue';

const translationStore = useTranslationStore();

const t = (label, modules) => {
  return translationStore.translate(label, modules);
};
</script>
<template>
      <section class="contant-us">
    <div class="container">

      <div class="title-area header-test-wrap">
        <div class="header-test-wrap">
          <p><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_312_6)">
            <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"></path>
            <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"></path>
            </g>
            <defs>
            <clipPath id="clip0_312_6">
            <rect width="20" height="20" fill="white"></rect>
            </clipPath>
            </defs>
            </svg>
          </span> 
          {{ t('contact-us-heading', ['contact-us']) }}
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_312_6)">
                <path d="M18.3139 13.4823C17.6282 12.7965 16.5126 12.7965 15.8269 13.4823L14.0284 15.2811C13.7783 14.615 13.1348 14.14 12.3816 14.14H10.4792C10.2094 14.14 10.0914 13.991 9.81596 13.7521C8.96448 13.0017 7.82635 12.6363 6.69666 12.7488C5.64847 12.8527 4.70425 13.3446 4.01014 14.14H1.75639C1.43322 14.14 1.17188 14.4014 1.17188 14.7274V19.4154C1.17188 19.7386 1.43322 20 1.75639 20C2.24817 20 13.0589 20 13.5535 20C14.0228 20 14.464 19.8173 14.7956 19.4857L18.3111 15.9697C18.9996 15.2811 18.9996 14.1681 18.3139 13.4823ZM3.59704 18.828H2.34652V15.312H3.59704V18.828ZM17.4877 15.1377L13.9722 18.6538C13.8626 18.7634 13.7109 18.8252 13.5591 18.8252C13.368 18.8252 4.95997 18.8252 4.77169 18.8252V15.0675C5.28033 14.4042 6.00817 13.9938 6.82312 13.9123C7.62964 13.8308 8.44179 14.0922 9.0516 14.629C9.34947 14.8904 9.74009 15.3092 10.4876 15.3092H12.3901C12.7132 15.3092 12.9746 15.5734 12.9746 15.8938C12.9746 16.217 12.7104 16.4812 12.3901 16.4812H9.99582C9.43378 16.4812 8.89985 16.7538 8.57106 17.2091C8.38278 17.4705 8.43898 17.8387 8.70314 18.027C8.96448 18.2153 9.33262 18.1591 9.5209 17.8949C9.63049 17.7431 9.80754 17.6532 9.99582 17.6532C10.6225 17.6532 12.4379 17.6532 13.0758 17.6532C13.2303 17.6532 13.3793 17.5914 13.4889 17.4817L16.6615 14.3086C16.8892 14.081 17.2629 14.081 17.4905 14.3086C17.7153 14.5391 17.7153 14.9101 17.4877 15.1377Z" fill="#894118"></path>
                <path d="M19.4155 10.6239H18.8113C18.5443 6.54019 15.5093 3.20967 11.6397 2.49016C11.7437 2.26813 11.7999 2.01799 11.7999 1.7566C11.7999 0.786959 11.0102 0 10.0435 0C9.07404 0 8.28438 0.789769 8.28438 1.7566C8.28438 2.01799 8.34339 2.26532 8.44456 2.49016C4.57495 3.20405 1.46691 6.52613 1.19432 10.6268H0.587325C0.264155 10.6268 0 10.8881 0 11.2114C0 11.5346 0.261345 11.7988 0.587325 11.7988H19.4155C19.7386 11.7988 20 11.5374 20 11.2114C20.0028 10.8853 19.7386 10.6239 19.4155 10.6239ZM10.0407 1.1692C10.3639 1.1692 10.6281 1.43339 10.6281 1.7538C10.6281 2.07701 10.3639 2.3412 10.0407 2.3412C9.71756 2.3412 9.45622 2.07701 9.45622 1.7538C9.45622 1.43058 9.71756 1.1692 10.0407 1.1692ZM2.36897 10.6239C2.6809 6.71444 6.06715 3.50478 10.0407 3.50478C14.0452 3.50478 17.3387 6.65261 17.6366 10.6239H2.36897Z" fill="#894118"></path>
                </g>
                <defs>
                <clipPath id="clip0_312_6">
                <rect width="20" height="20" fill="white"></rect>
                </clipPath>
                </defs>
                </svg>
            </span>
          </p>
        </div>
          <h2 class="title text-center" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp;">
            {{ t('contact-us-second-heading', ['contact-us']) }}
          </h2>
      </div>
    </div>
  </section>
  <section class="p-5 ps-0 contact-section">
    <div class="">
      <div class="content-form">
        <div class="row justify-content-center align-items-center mx-0">
          <div class="col-md-7 ps-0">
            <div class="img-cheif">
              <img src="@/assets/homepage/contact-us-form-img.png" alt="">
            </div>
          </div>
          <div class="col-md-5">
            <div class="contact-form-content-wrap">
              <h3 class="touch-us">{{ t('tell-us-heading-form', ['contact-us']) }}</h3>
              <div class="row ">
                <div class="col-lg-6 p-2">
                  <div class="input-wrap-withouticon password-bottom-space">
                    <!-- <label>Company Name</label> -->
                    <label for="exampleFormControlInput1" class="form-label"
            >{{ t('label-first-name', ['contact-us']) }}</label>
                    <input
                      type="text"
                      name="username"
                      class="form-control"
                      :placeholder="t('enter_first_name', ['contact-us'])"
                    />
                  </div>
                </div>
                <div class="col-lg-6 p-2">
                  <div class="input-wrap-withouticon password-bottom-space">
                    <!-- <label>Company Name</label> -->
                    <label for="exampleFormControlInput1" class="form-label"
            >{{ t('label-last-name', ['contact-us']) }}</label>
                    <input
                      type="text"
                      name="username"
                      class="form-control"
                      :placeholder="t('enter_last_name', ['contact-us'])"
                    />
                  </div>
                </div>
                <div class="col-lg-12 p-2">
                  <div class="input-wrap-withouticon password-bottom-space">
                    <!-- <label>Company Name</label> -->
                    <label for="exampleFormControlInput1" class="form-label"
            >{{ t('message', ['contact-us']) }}</label>
            <div class="form-floating">
              <textarea class="form-control" placeholder="Leave a Message here" id="floatingTextarea2" style="height: 150px"></textarea>
             
            </div>
                  </div>
                </div>

              </div>
              <div class="text-end mt-5 contant-btn">
                <button class="btn btn-primary " type="button">
                  {{ t('contact-us-btn', ['contact-us']) }}
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  </section>
  <FooterComponent />
</template>

<style>
 /* --------Contant-us------ */

 .contant-us{
    background-image: url('@/assets/homepage/Contant-us.png');
    width: 100%;
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contant-us .header-test-wrap p, .contant-us .header-test-wrap h2{
    color: #fff;
  }

  .contant-us .header-test-wrap p svg path{
    fill: #fff;
  }

  .contant-us .header-test-wrap h2{
    line-height: 40px;
    margin-top: 16px;

  }
  .contant-us .container{
    width: 40%;
  }

  @media (max-width: 991px) {
    .contant-us .container{
        width: 60%;
      }
      .touch-us {
        font-size: 20px !important;
      }
      .contact-section {
        padding-right: 16px !important;
      }
  }
  @media (max-width: 767px){
    .img-cheif img {
        height: 240px !important;
        border-radius: 0px !important;
    }
    .contact-section {
        padding: 35px 0 !important;
    }
    .contact-section .col-md-7.ps-0 {
        padding: 0px !important;
    }
    .contact-form-content-wrap {
        margin-top: -169px;
        margin-right: 20px !important;
        margin-left: 20px !important;
    }
    .contant-btn {
        margin-top: 20px !important;
    }
}
  @media (max-width: 600px) {
    .contant-us .container{
        width: 80%;
      }
  }
  

  @media (max-width: 500px) {
    .contant-us .container{
        width: 90%;
      }
      .contact-form-content-wrap {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .contact-form-content-wrap {
        padding: 25px 22px;
    }
    .contant-btn {
        margin-top: 16px !important;
    }
  }
  .img-cheif img{
    width: 100%;
    height: 580px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
  }
  .contact-form-content-wrap textarea{
    padding: 8px 16px !important;
  }
  .form-control {
    box-sizing: border-box !important;
  }
  .contact-form-content-wrap{
    padding: 30px 35px;
    background: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    box-sizing: border-box;
    margin-left: -90px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }
  .touch-us {
    text-align: center;
    font-size: 30px;
    margin-bottom: 16px;
  }

  /* ---------contact----- */
</style>